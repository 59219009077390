import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export default function MUICard(props: any) {
  const { title, btnLabel, btnAction, cardTextArray } = props;
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        {cardTextArray.map((cardText: any, index: any) => (
          <Typography
            key={index}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {cardText}
          </Typography>
        ))}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          onClick={btnAction}
          size="small"
          color="primary"
        >
          {btnLabel}
        </Button>
      </CardActions>
    </Card>
  );
}
