import React from "react";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

const MyIcon = (props: any) => {
  const { colors, status } = props;
  const setStyle = { color: colors.color, marginLeft: 5 };
  if (status === "W") {
    return <DoneIcon style={setStyle} />;
  } else if (status === "L") {
    return <ClearIcon style={setStyle} />;
  } else {
    return null;
  }
};
export default function MUIChip(props: any) {
  const { status, label, uniqueID, action } = props;
  const colors =
    status === "W"
      ? {
          bg: "#0F8367",
          color: "#fff"
        }
      : status === "L"
      ? {
          bg: "#E51A4A",
          color: "#fff"
        }
      : {
          bg: "#fff",
          color: "#000"
        };
  return (
    <Chip
      style={{
        background: colors.bg,
        color: colors.color,
        border: "1px solid",
        margin: 2,
        minWidth: 80
      }}
      label={label}
      clickable
      onClick={() => action(uniqueID)}
      icon={<MyIcon {...props} colors={colors} />}
    />
  );
}
