import * as React from 'react'

import DivisionLeader from './../data/DivisionLeader.json'
import GamesBackByTeam from './../data/GamesBackByTeam.json'
import H2H from './../data/H2H.json'
import { accumulateObjects } from './../helpers/accumulateObjects'
import { accumulateObjectsOfObjects } from './../helpers/accumulateObjectsOfObjects'
import { matchesByID } from './../helpers/matches'
import { standingsByID } from './../helpers/standings'
import { updateMatchReducer } from './updateMatchReducer'
import { updateAllMatchesReducer } from './updateAllMatchesReducer'
import {
  standingsData,
  sortPointsTable,
  gameBackByTeam,
  headToHead
} from 'fp-helpers-nba'
import { teams, teamsByID } from '../helpers/teams'

const conferences = ['East', 'West']
const NBAContext = React.createContext({})

const initialState = {
  conferences,
  standingsByID,
  divisionLeader: DivisionLeader,
  scheduledMatches: matchesByID,
  gamesBackByTeam: GamesBackByTeam,
  h2h: H2H
}

function NBAReducer (state, action) {
  switch (action.type) {
    case 'UPDATE_MATCH': {
      const withUpdatedMatch = {
        ...state.scheduledMatches,
        ...updateMatchReducer(state, action)
      }
      const withUpdatedMatchArray = Object.keys(withUpdatedMatch).map(
        match => withUpdatedMatch[match]
      )
      const newStandingsData = standingsData(
        teams,
        withUpdatedMatchArray,
        teamsByID,
        state.gamesBackByTeam
      )
      const finalStandings = accumulateObjects(standingsByID, newStandingsData)
      const newH2H = {
        ...state.h2h,
        ...accumulateObjectsOfObjects(
          state.h2h,
          headToHead(withUpdatedMatchArray)
        )
      }
      const newGameBackByTeam = gameBackByTeam(
        teamsByID,
        sortPointsTable(
          Object.keys(finalStandings).map(key => finalStandings[key]),
          teamsByID,
          state.divisionLeader,
          newH2H
        )
      )
      return {
        ...state,
        scheduledMatches: withUpdatedMatch,
        gamesBackByTeam: newGameBackByTeam,
        standingsByID: {
          ...finalStandings
        },
        h2h: newH2H
      }
    }
    case 'UPDATE_MATCHES': {
      const completedMatches = updateAllMatchesReducer(state, action)
      const completedMatchesArray = Object.keys(completedMatches).map(
        match => completedMatches[match]
      )
      const newStandingsData = standingsData(
        teams,
        completedMatchesArray,
        teamsByID,
        state.gamesBackByTeam
      )
      const newH2H = {
        ...state.h2h,
        ...accumulateObjectsOfObjects(
          state.h2h,
          headToHead(completedMatchesArray)
        )
      }
      const finalStandings = accumulateObjects(standingsByID, newStandingsData)
      const newGameBackByTeam = gameBackByTeam(
        teamsByID,
        sortPointsTable(
          Object.keys(finalStandings).map(key => finalStandings[key]),
          teamsByID,
          state.divisionLeader,
          newH2H
        )
      )
      return {
        ...state,
        gamesBackByTeam: newGameBackByTeam,
        scheduledMatches: completedMatches,
        standingsByID: {
          ...finalStandings
        },
        h2h: newH2H
      }
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

function NBAProvider (props) {
  const [state, dispatch] = React.useReducer(NBAReducer, initialState)
  const value = React.useMemo(() => [state, dispatch], [state])

  return <NBAContext.Provider value={value} {...props} />
}

function useNBA () {
  const context = React.useContext(NBAContext)
  if (!context) {
    throw new Error('must be used within a Provider')
  }
  const [state, dispatch] = context
  // console.log({ state })
  const updateMatch = matchID =>
    dispatch({
      type: 'UPDATE_MATCH',
      matchID
    })
  const updateMatches = () =>
    dispatch({
      type: 'UPDATE_MATCHES'
    })
  return {
    state,
    updateMatch,
    updateMatches
  }
}

export { NBAProvider, useNBA }
