import { standingsByID } from "./../helpers/standings";
import { predictWinner } from "./../helpers/predictWinner";
import { arrToObj, winPercentageAsNum } from "fp-helpers-nba";
export const updateAllMatchesReducer = (state: any) => {
  const homeScores = [90, 100];
  const awayScores = [92, 99];
  const updatedMatches = Object.keys(state.scheduledMatches).map(matchID => {
    const matchData = state.scheduledMatches[matchID];
    const awayID = matchData.vTeam.teamId;
    const awayData = standingsByID[awayID];
    const awayWinPer = winPercentageAsNum(awayData.winPercentageTotal);
    const homeID = matchData.hTeam.teamId;
    const homeData = standingsByID[homeID];
    const homeWinPer = winPercentageAsNum(homeData.winPercentageTotal);
    const winner = predictWinner(homeID, homeWinPer, awayID, awayWinPer);
    const winnerIndex = winner === awayID ? 1 : 0;
    return {
      ...matchData,
      statusGame: "Finished",
      vTeam: {
        ...matchData.vTeam,
        points: homeScores[winnerIndex]
      },
      hTeam: {
        ...matchData.hTeam,
        points: awayScores[winnerIndex]
      }
    };
  });
  return arrToObj(updatedMatches, "gameId", true);
};
