export const updateMatchReducer = (state: any, action: any) => {
  const curMatch = state.scheduledMatches[action.matchID];
  const homePoints = curMatch.hTeam.points;
  const awayPoints = curMatch.vTeam.points;
  /** if a blank match set home team to win first else set away */
  const newHomePts =
    homePoints === "" ? 100 : homePoints > awayPoints ? 98 : 100;
  const newAwayPts =
    awayPoints === "" ? 98 : homePoints > awayPoints ? 100 : 98;
  return {
    ...state.scheduledMatches,
    [action.matchID]: {
      ...curMatch,
      statusGame: "Finished",
      vTeam: {
        ...curMatch.vTeam,
        points: newAwayPts
      },
      hTeam: {
        ...curMatch.hTeam,
        points: newHomePts
      }
    }
  };
};
