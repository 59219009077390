import React from "react";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import Typography from "@material-ui/core/Typography";
import MUIChip from "./MUIChip";
import { useNBA } from "../../providers/NBAProvider";
import { teamsByID } from "../../helpers/teams";
import { getOpponent, isHomeID, matchWinnerID } from "fp-helpers-nba";

export interface ConfirmationDialogRawProps {
  id: string;
  curID: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}
export default function MUIDialog(props: ConfirmationDialogRawProps) {
  const { onClose, curID, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const { state, updateMatch } = useNBA();
  const scheduledMatchesByID = state.scheduledMatches;
  const curIDScheduledMatches = Object.keys(scheduledMatchesByID)
    .map(matchID => scheduledMatchesByID[matchID])
    .filter(
      match => match.hTeam.teamId === curID || match.vTeam.teamId === curID
    );

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {};

  const handleOk = () => {
    onClose(value);
  };

  /*
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  */
  const teamName =
    teamsByID[curID] !== undefined ? teamsByID[curID].shortName : "";

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <Typography
        style={{ padding: "10px 10px 5px", margin: 0 }}
        gutterBottom
        variant="h5"
        component="h2"
      >
        {`${teamName} Upcoming Games`}
      </Typography>
      <Typography
        style={{ padding: "0px 10px 5px" }}
        variant="body2"
        color="textSecondary"
        component="p"
      >
        Tap opponents to toggle between W / L
      </Typography>
      <DialogContent dividers>
        {curIDScheduledMatches.length ? (
          <div className="chip-wrap">
            {curIDScheduledMatches.map(match => {
              const awayStr = isHomeID(match, curID) ? "" : "@";
              const isUnplayed =
                match.statusGame === "Scheduled" ? true : false;
              const isWinner = matchWinnerID(match) === curID ? true : false;
              return (
                <MUIChip
                  key={match.gameId}
                  uniqueID={match.gameId}
                  label={`${awayStr}${
                    teamsByID[getOpponent(match, curID)].shortName
                  }`}
                  status={
                    isUnplayed === true ? "" : isWinner === true ? "W" : "L"
                  }
                  action={updateMatch}
                />
              );
            })}
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
