import React, { FC } from "react";
import { RouteComponentProps } from "@reach/router";
import ReactGA from "react-ga";

interface ExtendProps extends React.PropsWithChildren<any> {
  pageComponent: FC;
}
const trackPage = (page: any) => {
  ReactGA.set({
    page
  });
  ReactGA.initialize("UA-60239298-1");
  ReactGA.pageview(page);
};

const RouterPage = ({
  children,
  ...props
}: ExtendProps & RouteComponentProps): React.ReactElement => {
  const { pageComponent, ...others } = props;
  const page = window.location.pathname;
  const host = window.location.host;
  if (!host.includes("local")) {
    trackPage(page);
  }
  return <props.pageComponent {...others}>{children}</props.pageComponent>;
};

export default RouterPage;
