export const predictWinner = (
  homeID: any,
  homeWinPer: any,
  awayID: any,
  awayWinPer: any
) => {
  const remChance = 2000 - (homeWinPer + awayWinPer);
  const homeChance = Number((homeWinPer + remChance * 0.6).toFixed());

  const randomNum = Math.floor(Math.random() * 2000) + 1;

  if (randomNum >= homeChance) {
    return awayID;
  } else {
    return homeID;
  }
};
