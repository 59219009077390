import { winPercentage } from "fp-helpers-nba";

export const accumulateObjects = (object1: any, object2: any) =>
  Object.keys(object1).reduce((obj, id) => {
    return {
      ...obj,
      [id]: Object.keys(object1[id]).reduce((obj2, key) => {
        const calcWinPercentage = (key1: any, key2: any) =>
          winPercentage(
            object1[id][key1] + object2[id][key1],
            object1[id][key2] + object2[id][key2]
          );
        return {
          ...obj2,
          [key]: Number.isInteger(object1[id][key])
            ? object1[id][key] + object2[id][key]
            : object1[id][key],
          winPercentageTotal: calcWinPercentage(
            "matchesTotalCount",
            "wonTotal"
          ),
          winPercentageHome: calcWinPercentage("matchesHomeCount", "wonHome"),
          winPercentageAway: calcWinPercentage("matchesAwayCount", "wonAway"),
          winPercentageDivision: calcWinPercentage(
            "matchesDivisionCount",
            "wonDivision"
          ),
          winPercentageConference: calcWinPercentage(
            "matchesConferenceCount",
            "wonConference"
          ),
          winPercentageConfEligible: calcWinPercentage(
            "matchesConfEligibleCount",
            "wonConfEligible"
          ),
          winPercentageOConfEligible: calcWinPercentage(
            "matchesOConfEligibleCount",
            "wonOConfEligible"
          )
        };
      }, {})
    };
  }, {});
