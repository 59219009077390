import * as React from "react";
import "./styles.css";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import MUIAppBarWrap from "./components/mui/MUIAppBarWrap";
import MUITabs from "./components/mui/MUITabs";
import MUITable from "./components/mui/MUITable";
import MUICard from "./components/mui/MUICard";
import MUIDialog from "./components/mui/MUIDialog";

import { useNBA } from "./providers/NBAProvider";
import { teamsByID } from "./helpers/teams";
import { sortPointsTable } from "fp-helpers-nba";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#0b3661",
      main: "#104e8b",
      dark: "#3f71a2",
      contrastText: "#fff"
    }
  }
});

export default function App() {
  const [open, setOpen] = React.useState(false);
  const [curID, setCurID] = React.useState("");
  const [value, setValue] = React.useState("Dione");

  const handleClickListItem = (id: any) => {
    setCurID(id);
    setOpen(true);
  };

  const handleClose = (newValue: any) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };
  const { state, updateMatches } = useNBA();
  const {
    standingsByID,
    conferences,
    gamesBackByTeam,
    divisionLeader,
    h2h
  } = state;
  const standings = sortPointsTable(
    Object.keys(standingsByID).map(teamID => standingsByID[teamID]),
    teamsByID,
    divisionLeader,
    h2h
  );
  const standingsByConference = conferences.reduce(
    (obj: any, conference: any) => {
      return {
        ...obj,
        [conference]: standings.filter((team: any) => {
          return teamsByID[team.teamId].confName === conference;
        })
      };
    },
    {}
  );
  const siteTitle = "Finals Predictor";
  return (
    <div className="app-wrap">
      <ThemeProvider theme={theme}>
        <MUIAppBarWrap title={siteTitle} rightContent={null} />
        <MUICard
          title="NBA Playoffs Predictor"
          btnLabel={"Predict"}
          btnAction={updateMatches}
          cardTextArray={[
            "Predict where your team will finish after the regular season.",
            "Click Predict to auto generate results.",
            "Click on a team in the standings to manually predict their results"
          ]}
        />
        <MUITabs
          label={"innings"}
          data={conferences.map((item: string) => ({ label: item }))}
          tabContent={Object.keys(standingsByConference).map(conference => (
            <MUITable
              titleCaseHead={true}
              columns={[
                { key: `pos-${conference}`, label: "pos" },
                {
                  key: `team-${conference}`,
                  label: "team"
                },
                {
                  key: `p-${conference}`,
                  label: "p"
                },
                {
                  key: `w-${conference}`,
                  label: "w"
                },
                {
                  key: `l-${conference}`,
                  label: "l"
                },
                {
                  key: `gb-${conference}`,
                  label: "GB"
                }
              ]}
              rowAction={(id: any) => handleClickListItem(id)}
              rows={standingsByConference[conference].map(
                (team: any, index: any) => {
                  return {
                    label: team.name,
                    pos: index + 1,
                    team: team.name,
                    p: team.matchesTotalCount,
                    w: team.wonTotal,
                    l: team.lostTotal,
                    GB: gamesBackByTeam[team.teamId].conference,
                    highlight: index === 7 ? true : false,
                    id: team.teamId
                  };
                }
              )}
              key={conference}
            />
          ))}
        />
        <MUIDialog
          id="games-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
          curID={curID}
        />
      </ThemeProvider>
    </div>
  );
}
