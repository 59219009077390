export const accumulateObjectsOfObjects = (object1: any, object2: any) =>
  Object.keys(object1).reduce((obj, item) => {
    return {
      ...obj,
      [item]: {
        ...object1[item],
        ...object2[item]
      }
    };
  }, {});
