import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

export default function MUITabs(props: any) {
  const { label, data, tabContent } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (e: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper square>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label={label}
        centered
      >
        {data.map((tab: any) => (
          <Tab
            key={tab.label.replace(" ", "")}
            label={tab.label}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>
      {tabContent[value]}
    </Paper>
  );
}
