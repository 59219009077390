import * as React from "react";
import { render } from "react-dom";
import { Router } from "@reach/router";
import { NBAProvider } from "./providers/NBAProvider";

import App from "./App";
import RouterPage from "./RouterPage";
/*import Home from "./Home";*/

const rootElement = document.getElementById("root");
render(
  <NBAProvider>
    <Router>
      {/*<Home path="/" />*/}
      <RouterPage default={true} path="" pageComponent={App} />
    </Router>
  </NBAProvider>,
  rootElement
);
