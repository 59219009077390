import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export default function MUITable(props: any) {
  const { columns, rows, titleCaseHead = false, rowAction } = props;
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((col: any) => {
              const setHeadAllighment =
                col.align !== undefined ? col.align : "center";
              return (
                <TableCell
                  className={
                    col.extraSelector !== undefined
                      ? `${col.extraSelector} tbpad`
                      : "tbpad"
                  }
                  key={col.key}
                  align={setHeadAllighment}
                  padding="none"
                  style={titleCaseHead ? { textTransform: "capitalize" } : {}}
                >
                  {col.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => {
            const setBodyAllighment =
              row.align !== undefined ? row.align : "center";
            const setHighlight =
              row.highlight !== undefined ? row.highlight : false;
            return (
              <TableRow
                style={{ cursor: "pointer" }}
                onClick={() => rowAction(row.id)}
                key={row.label}
                className={setHighlight ? "bold" : ""}
              >
                {columns.map((col: any) => {
                  return (
                    <TableCell
                      className={
                        col.extraSelector !== undefined
                          ? `${col.extraSelector} tbpad`
                          : "tbpad"
                      }
                      key={col.label}
                      padding="none"
                      align={setBodyAllighment}
                    >
                      {row[col.label] !== undefined ? row[col.label] : ""}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
